import { ActiveOrClosedCard } from './ActiveOrClosedCard';

export enum ResponseRateStatus {
  ACTIVE = 'active',
  CLOSED = 'closed',
}

type Props = {
  status: ResponseRateStatus | string;
  disclosureCycleId: string;
};

export const DistributionAndResponseRateCard = ({ status, disclosureCycleId }: Props) => {
  switch (status) {
    case 'active':
    case 'closed': {
      return <ActiveOrClosedCard disclosureCycleId={disclosureCycleId} />;
    }
    default: {
      throw new Error('Unknown disclosure list status');
    }
  }
};

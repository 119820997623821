import { useTranslation } from 'next-i18next';
import router from 'next/router';
import Loading from '@/components/common/loading/Loading';
import { CREATE_A_DISCLOSER } from '@/graphql/disclosure/mutations';
import { GET_USER_TASKS } from '@/graphql/disclosure/queries';
import { CreateDiscloserListMutation, GetAuthorityUserTasksQuery } from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useMutation, useQuery } from '@apollo/client';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { Alert, Button } from '@mui/material';

type Props = {
  adjust_margin?: number;
  variant?: 'text' | 'contained' | 'outlined';
  currentYear: number;
};
export const CreateDiscloserButton = ({ adjust_margin = 16, variant = 'contained', currentYear }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'common' });
  const { authorityTypeId } = useStore();

  const { loading, error, data } = useQuery<GetAuthorityUserTasksQuery>(GET_USER_TASKS, {
    client: disclosureClient,
    fetchPolicy: 'network-only',
    variables: {
      authorityTypeId,
    },
    onError: onApolloError,
  });

  const [
    createDiscloser,
    { loading: isCreatingDiscloser, error: createError },
  ] = useMutation<CreateDiscloserListMutation>(CREATE_A_DISCLOSER, {
    variables: {
      createDiscloserListInput: {
        authorityTypeId,
      },
    },
    client: disclosureClient,
  });

  const handleCreateDiscloser = async () => {
    try {
      const res = await createDiscloser();
      router.push({
        pathname: Routes.Authority.DiscloserList,
        query: {
          discloserListId: res.data?.createDiscloserList.discloserListId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  error && <Alert severity="error">{error.message}</Alert>;

  if (!data?.getAuthorityUserTasks?.createActiveCycleList && !data?.getAuthorityUserTasks?.createNextCycleList) {
    return null;
  }

  return (
    <>
      {createError && <Alert severity="error">{createError.message}</Alert>}
      <Button
        variant={variant}
        startIcon={<AddCircleOutlineOutlined />}
        disabled={isCreatingDiscloser}
        sx={{
          marginTop: adjust_margin,
          width: 'fit-content',
        }}
        onClick={handleCreateDiscloser}
      >
        {data?.getAuthorityUserTasks?.createActiveCycleList
          ? t('cardPromptCreateActiveCtaLabel', { year: currentYear })
          : t('cardPromptCreateNextCtaLabel', { year: currentYear })}
      </Button>
    </>
  );
};

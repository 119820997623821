import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loading from '@/components/common/loading/Loading';
import { InvoiceStatus } from '@/components/payment/types';
import { GET_MEMBERSHIP_INVOICE, GET_MY_ORG_COUNTRY } from '@/graphql/disclosure/queries';
import {
  GetMembershipInvoiceQuery,
  GetMembershipInvoiceQueryVariables,
  GetMyOrganisationDetailsQuery,
} from '@/lib/discloser/__generated__/graphql';
import { useStore } from '@/store/authorityType';
import { disclosureClient } from '@/utils/apollo-client';
import { onApolloError } from '@/utils/errorFormat';
import { Routes } from '@/utils/urls';
import { useQuery } from '@apollo/client';
import { Alert, AlertTitle, Grid, Link } from '@mui/material';

export const AlertCMInvoiceStatus = () => {
  const { t } = useTranslation([
    'questionnaire',
  ]);

  const searchParams = useSearchParams();
  const invoiceSubmitStatus = searchParams.get('invoicesubmitstatus');
  const paymentSubmitStatus = searchParams.get('paymentstatus');
  const paymentStatus = searchParams.get('paymentstatus');

  const [
    invoiceSubmitStatusAlertOpen,
    setInvoiceSubmitStatusAlertOpen,
  ] = useState(!!invoiceSubmitStatus);

  const [
    paymentSubmitStatusAlertOpen,
    setPaymentSubmitStatusAlertOpen,
  ] = useState(!!paymentSubmitStatus);

  const [
    paymentReminderAlert,
    setPaymentReminderAlert,
  ] = useState(false);

  const { authorityTypeId } = useStore();

  const { data: orgCountryData, loading: orgCountryLoading } = useQuery<GetMyOrganisationDetailsQuery>(
    GET_MY_ORG_COUNTRY,
    {
      fetchPolicy: 'network-only',
      client: disclosureClient,
      onError: onApolloError,
    },
  );

  const { data: membershipInvoice, loading: membershipInvoiceLoading } = useQuery<
    GetMembershipInvoiceQuery,
    GetMembershipInvoiceQueryVariables
  >(GET_MEMBERSHIP_INVOICE, {
    variables: {
      status: [
        InvoiceStatus.PAID,
        InvoiceStatus.UNPAID,
        InvoiceStatus.FREE,
        InvoiceStatus.FAILED,
      ],
      authorityTypeId: authorityTypeId,
    },
    client: disclosureClient,
    fetchPolicy: 'network-only',
    onError: onApolloError,
  });

  useEffect(() => {
    const invoice = membershipInvoice?.getMembershipInvoice;
    setPaymentReminderAlert(
      !invoiceSubmitStatusAlertOpen &&
        (invoice?.status === InvoiceStatus.UNPAID ||
          (invoice?.status === InvoiceStatus.FAILED && !!invoice?.invoiceNumber)),
    );
  }, [
    invoiceSubmitStatusAlertOpen,
    membershipInvoice?.getMembershipInvoice?.status,
  ]);

  const creditCardAccepted = orgCountryData?.getMyOrganisationDetails?.OrgCountry?.creditCardAccepted === true;

  if (orgCountryLoading || membershipInvoiceLoading) {
    return <Loading isContained />;
  }

  return (
    <Grid
      container
      rowSpacing={3}
      columnSpacing={3}
    >
      {(invoiceSubmitStatus || paymentStatus) && (
        <Grid
          item
          xs={12}
        >
          {(invoiceSubmitStatus?.toLowerCase() === 'success' ||
            invoiceSubmitStatus?.toLowerCase() === 'failed' ||
            paymentStatus?.toLowerCase() === 'transactionsuccessful') &&
            (invoiceSubmitStatusAlertOpen || paymentSubmitStatusAlertOpen) && (
              <Alert
                onClose={() => {
                  setInvoiceSubmitStatusAlertOpen(false);
                  setPaymentSubmitStatusAlertOpen(false);
                }}
                severity={
                  invoiceSubmitStatus?.toLowerCase() === 'success' ||
                  paymentStatus?.toLowerCase() === 'transactionsuccessful'
                    ? 'success'
                    : 'error'
                }
                sx={{ whiteSpace: 'pre-line' }}
                className="invoice-alert"
                data-testid="invoice-alert"
              >
                <>
                  <AlertTitle>
                    {invoiceSubmitStatus?.toLowerCase() == 'success'
                      ? t('alerts.invoiceSubmitStatus.success.title')
                      : invoiceSubmitStatus?.toLowerCase() == 'failed'
                        ? t('alerts.invoiceSubmitStatus.error.title')
                        : paymentStatus?.toLowerCase() == 'transactionsuccessful'
                          ? t('alerts.paymentStatus.title.success')
                          : ''}
                  </AlertTitle>
                  {invoiceSubmitStatus?.toLowerCase() == 'success'
                    ? t('alerts.invoiceSubmitStatus.success.description')
                    : invoiceSubmitStatus?.toLowerCase() == 'failed'
                      ? t('alerts.invoiceSubmitStatus.error.description')
                      : paymentStatus?.toLowerCase() == 'transactionsuccessful'
                        ? t('alerts.paymentStatus.description.success')
                        : ''}
                </>
              </Alert>
            )}
        </Grid>
      )}
      <Grid
        item
        md={12}
      >
        {paymentReminderAlert && (
          <Alert
            onClose={() => {
              setPaymentReminderAlert(false);
            }}
            severity="warning"
            sx={{ whiteSpace: 'pre-line' }}
            className="invoice-alert"
          >
            <AlertTitle>{t('questionnaire.paymentReminder.title')}</AlertTitle>
            {creditCardAccepted ? (
              <Trans
                i18nKey="questionnaire.paymentReminder.allowedPayByCardDescription"
                ns="questionnaire"
                components={[
                  <Link
                    href={Routes.Payment}
                    key="linkComponent"
                    color="inherit"
                    underline="always"
                    sx={{ cursor: 'pointer' }}
                    aria-label="payment-link"
                  />,
                ]}
              />
            ) : (
              t('questionnaire.paymentReminder.notAllowedPayByCardDescription')
            )}
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};
